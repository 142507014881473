/**
 * 获取dom在文档中的位置坐标
 * @param {node} e 
 * @param {node | null} end 
 * @returns {object<{left: Number, top: Number}>}
 */
export default function getNodeOffset(e, end = null) {
  function getTop(ev){
    let offset = ev.offsetTop;
    if(ev.offsetParent == end) return offset;       
    offset += getTop(ev.offsetParent); 
    return offset;
  }

  function getLeft(ev){
    let offset = ev.offsetLeft;
    if(ev.offsetParent == end) return offset;
    offset += getLeft(ev.offsetParent);
    return offset;
  }
  
  return {left: getLeft(e), top: getTop(e)}
}