<template>
  <div class="pop-up">
    <div class="pop-up-box">
      <div class="title">{{ message }}</div>
      <div class="content">{{ message }}</div>
      <div class="operation">
        <span class="btn">Save & Exit</span>
        <span class="btn">Discard</span>
        <img @click="$emit('close')" src="@/assets/images/close.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'All unsaved changes will be lost.'
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="scss">
.pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .4);
  display: flex;
  justify-content: center;
  align-items: center;

  .pop-up-box {
    width: 21.875rem;
    background-color: #F0F0EF;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);

    .title {
      width: 100%;
      padding: 1.25rem;
      text-align: center;
      color: #000;
      font-weight: 700;
      font-size: 1.5rem;
    }

    .content {
      width: 100%;
      padding: 1.25rem;
      box-sizing: border-box;
      color: #322F35;
      font-size: .875rem;
    }

    .operation {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn {
        color: #1B7379;
        font-size: .875rem;
        font-weight: 700;
        margin: 0 .9375rem;
        cursor: pointer;
      }

      img {
        width: 1.5rem;
        height: auto;
        cursor: pointer;
      }
    }
  }
}
</style>