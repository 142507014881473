<template>
  <div class="datePicker">
    <span class="label" v-if="label">{{ label }}</span>
    <div class="mock-picker">
      <div class="self-picker" @click="showCard">
        <span class="date-info">{{ timeStr }}</span>
        <img class="date-icon" src="@/assets/images/ion_time-outline.png" alt="">
      </div>

      <div class="time-card" v-show="cardSwitch" tabindex="1" @blur="cardSwitch = false">
        <div class="title">Select time</div>

        <!-- 显示时间 -->
        <div class="time">
          <div class="hour">{{ time.hour }}</div>
          <div class="minute">{{ time.minute }}</div>
          <div class="am-pm">
            <div :class="[its.label, its.select && 'select']" @click="clickAPM(idx)"
            v-for="(its, idx) in apm" :key="idx">{{ its.value }}</div>
          </div>
        </div>

        <!-- 时钟轮盘 -->
        <div class="clock">
          <div class="cricle">
            <!-- 轮盘中心 -->
            <div class="cricle-center"></div>
            <!-- 指针 -->
            <div class="pointer" @mousedown="pointerDown"></div>
            <!-- 刻度 -->
            <div class="scale" v-for="(its) in minute" :key="its + '01'"
              :style="its % 5 == 0 && 'width: 4px; height: 10px'"></div>
            <!-- 小时 -->
            <div class="quarter" v-for="(clock, idx) in hours" :key="idx">
              <span>{{ clock }}</span>
            </div>
          </div>
        </div>

        <div class="bottom">
          <span @click="cardSwitch = false">Cancel</span>
          <span @click="saveTime">OK</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import getNodeOffset from '@/utils/getNodeOffset.js'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    joiningDate: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    joiningDate: function() {
      this.handleTime(this.joiningDate, 'apm')
    }
  },
  data() {
    let hours = new Array(12).fill().map((its, idx) => idx + 1),
    minute = new Array(60).fill().map((its, idx) => idx + 1)

    return {
      timeStr: '',
      hours, 
      minute,

      time: {
        hour: '00',
        minute: '00',
      },
      apm: [
        {label: 'am', value: 'AM', select: true},
        {label: 'pm', value: 'PM', select: false},
      ],

      page: {
        initLeft: 0,
        initTop: 0
      },
      cardSwitch: false
    }
  },
  computed: {
    tempTimeStr: function(){
      let [{label}] = this.apm.filter(its => its.select && its),
      {hour, minute} = this.time
      return `${hour}:${minute} ` + label.toUpperCase()
    }
  },
  methods: {
    // 保存时间
    saveTime() {
      this.timeStr = this.tempTimeStr
      this.cardSwitch = false

      // 处理时间 
      let time = this.handleTime(this.timeStr, 'time')
      this.$emit('updateTime', time)
    },
    // 处理时间
    handleTime(timeStr, convertType) {
      timeStr = timeStr || '00:00'

      if(convertType == 'time') {
        let [time, amp] = timeStr.split(' ')
        if(amp == 'AM') return time
        else {
          let [hours, minute] = time.split(':')
          return Number(hours) + 12 + ":" + minute
        }
      } else if(convertType == 'apm') {
        let [hours, minute] = timeStr.split(':')
        
        if(hours < 12) this.apm.filter((its, idx) => idx == 0 ? its.select = true : its.select = false)
        else {
          hours -= 12
          this.apm.filter((its, idx) => idx == 1 ? its.select = true : its.select = false)
        }

        this.time = {
          hour: String(hours).length == 1 ? '0' + hours : String(hours),
          minute: minute,
        }

        this.timeStr = this.tempTimeStr
      }
    },
    // 显示时间选择器
    showCard() {
      this.cardSwitch = true

      this.$nextTick(() => {
        let card = document.querySelector('.time-card')
        card.focus()
        this.handleTime(this.joiningDate, 'apm')
        this.style()
      })
    },
    clickAPM(idx) {
      this.apm.filter((its, index) => idx == index ? its.select = true : its.select = false)
    },
    // 拖动指针修改时间
    pointerDown() {
      let cricle = document.querySelector('.cricle-center'),
      pointer = document.querySelector('.pointer'),
      {left, top} = getNodeOffset(cricle)
      
      document.body.onmousemove = ev => {
        let {pageX, pageY} = ev,
        // 出现滚动条时 pageX, pageY与 left, top会出现差值 需要同步数据
        {initLeft, initTop} = this.page,
        // 获取角度
        rotate = Math.round(Math.atan2(pageX + initLeft - left, pageY + initTop - top) * (180 / Math.PI))
        // 同步样式
        pointer.style.transform = `rotate(${180 - rotate}deg)`
        
        // 根据角度算出时间
        let hour = Math.floor((180 - rotate) / 30) + '',
        minute = ((180 - rotate) - hour * 30) * (60 / 30) + ''

        hour = hour.length == 2 ? hour : `0` + hour
        minute = minute.length == 2 ? minute : `0` + minute
        
        this.time = { hour, minute }
      }

      // 取消事件
      document.body.onmouseup = () => document.body.onmousemove = null
    },

    // 回显布局
    style() {
      // 回显小时数字
      let quarters = document.querySelectorAll('.quarter')
      for(let i = 0; i < quarters.length; i++) {
        let quarter = quarters[i],
        quarterSpan = quarter.querySelector('span')
        quarter.style.transform = `rotate(${30 + i * 30}deg)`
        quarterSpan.style.transform = `rotate(-${30 + i * 30}deg)`
      }

      // 回显刻度
      let scales = document.querySelectorAll('.scale')
      for(let i = 0; i < scales.length; i++) {
        let scale = scales[i]
        scale.style.transform = `rotate(${6 + i * 6}deg)`
      }

      // 回显指针
      let {hour, minute} = this.time
      , rotate = hour * 30 + minute * .5
      , pointer = document.querySelector('.pointer')
      pointer.style.transform = `rotate(${rotate}deg)`
    },

    /**
     * 查询 plate 存在滚动条的所有父节点
     * @param {node} element (default: document.body) 默认以整个文档为范围进行查询
     */
    findscroller(element = document.body) {
      let that = this,
      plate = document.querySelector('.cricle')
      element.onscroll = () => {
        // 过滤
        if(element.contains(plate)){
          this.page = {
            initLeft: element.scrollLeft,
            initTop: element.scrollTop
          }
        }
      }
      Array.from(element.children).forEach(its => that.findscroller(its))
    },
  },
  mounted() {
    this.findscroller()
  }
}
</script>

<style scoped lang="scss">
.datePicker {
  display: flex;
  align-items: center;

  .label {
    white-space: nowrap;
    font-size: 1.125rem;
    margin-right: .625rem;
    margin-left: 1.25rem;
  }
  .mock-picker {
    width: 225px;
    height: 50px;
    position: relative;
    .self-picker {
      width: 100%;
      height: 100%;
      cursor: pointer;
      border-radius: .9375rem;
      border: 1px solid #6D6D6D;
      background-color: #fff;
      padding: 0 1.25rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date-info {
        font-size: 1.125rem;
      }

      .date-icon {
        width: 30px;
        height: auto;
      }
    }

    .time-card {
      position: absolute;
      left: -50%;
      right: -50%;
      margin: auto;
      margin-top: 10px;
      width: 330px;
      border-radius: 25px;
      background-color: #fff;
      padding: 25px;
      box-sizing: border-box;
      box-shadow: 0 10px 28px 0 rgba(0, 0, 0, .25);
      z-index: 9999;
      
      .title {
        color: #6D6D6D;
        font-size: 16px;
        margin-bottom: 20px;
      }

      .time {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .hour, .minute {
          width: 96px;
          height: 100%;
          border-radius: 8px;
          display: flex;
          background-color: #F0F0EF;
          justify-content: center;
          align-items: center;
          font-size: 57px;
          color: #21005D;
        }

        .hour { 
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 35%;
            right: -13px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #1D1B20;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 25%;
            right: -13px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #1D1B20;
          }
        }

        .select {
          background-color: #E5F9FF;
        }

        .am-pm {
          width: 52px;
          height: 100%;
          border-radius: 8px;
          border: 1px solid #6D6D6D;

          .am, .pm {
            width: 100%;
            height: 50%;
            border-radius: 8px 8px 0 0;
            background-color: #F0F0EF;
            color: #6D6D6D;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            cursor: pointer;
          }

          .pm {
            border-radius: 0 0 8px 8px ;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #6D6D6D;
            }
          }

          .select {
            background-color: #E5F9FF;
            color: #31111D;
          }
        }
      }

      .clock {
        width: 100%;
        margin: 40px 0;
        display: flex;
        justify-content: center;
        user-select: none;

        .cricle {
          width: 256px;
          height: 256px;
          border-radius: 50%;
          background-color: #F0F0EF;
          box-sizing: border-box;
          position: relative;
          .cricle-center {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 8px;
            height: 8px;
            background-color: #1B7379;
            border-radius: 50%;
          }
          .pointer {
            position: absolute;
            top: 48px;
            left: 0;
            right: 0;
            margin: auto;
            width: 2px;
            height: 80px;
            background-color: #1B7379;
            transform-origin: center bottom;
            transform: rotate(0);
            cursor: pointer;
          }

          .scale {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 2px;
            height: 5px;
            background-color: #000;
            transform-origin: 50% 128px;
          }

          .quarter {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            color: #1D1B20;
            font-size: 16px;
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            transform-origin: 50% 128px;
            cursor: pointer;

            & span {
              transform-origin: center center;
            }
          }

          .select {
            background-color: #1B7379;
            color: #fff;
          }
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          margin-left: 40px;
          color: #1B7379;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>