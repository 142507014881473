<template>
  <div class="module-header">
    <div class="title">
      <img v-if="leading" class="leading" src="@/assets/images/leading.png" @click="$router.go(-1)" alt="">
      {{ title }}
    </div>
    <div class="operation">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
}
</script>
