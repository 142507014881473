/**
 * PopUp
 */
class PopUp {

  constructor() {
    
  }
  /**
   * new PopUp().config({
   *  title: '标题',
   *  content: '内容',
   *  confirm: {
   *    btnColor: '#1B7379',
   *    btn: '确认',
   *    e: () {}
   *  },
   *  cancel: {
   *    btnColor: '#1B7379',
   *    btn: '关闭',
   *    e: () {}
   *  }
   * })
   */
  config(params) {
    this.echoHtml(params)
  }

  confirm(callback) {
    callback && callback.__proto__.constructor.name == 'Function' && callback()
  }

  cancel(callback) {
    this.removePopup()

    callback && callback.__proto__.constructor.name == 'Function' && callback()
  }

  removePopup() {
    let popupNode = document.querySelector('.pop-up')
    if(popupNode) {
      document.body.removeChild(popupNode)
      popupNode = null
    }
  }

  echoHtml(params) {
    this.removePopup()
    let { title, content, confirm, cancel } = params
    , vnode = this.h(
      'div', {class: 'pop-up'}, [
        this.h('div', {class: 'pop-up-box'}, [
          title ? this.h('div', {class: 'title'}, title) : null,
          this.h('div', {class: 'content'}, content),
          this.h('div', {class: 'operation'}, [
            this.h('span', {class: 'btn confirm', style: `color: ${confirm.btnColor}`, onClick: () => this.confirm(confirm.e)}, confirm.btn || 'Confirm'),
            this.h('span', {class: 'btn cancel', style: `color: ${cancel && cancel.btnColor}`, onClick: () => this.cancel(cancel && cancel.e)}, (cancel && cancel.btn) || 'Cancel'),
            this.h('img', {src: require('@/assets/images/close.png'), onClick: () => this.cancel(cancel && cancel.e)}),
          ]),
        ])
      ]
    )
   
    this.mount(vnode, document.body)
  }

  /**
   * h({tag: 'div', class: 'name', id: 'id', value: ''
   *    childNode: [
   *       {tag: 'div', class: 'name', id: 'id' }
   *    ]
   * })
   */
  h(tag, props, children) {
    return {
      tag, props, children
    }
  }
  mount(vnode, container) {
    if(!vnode) return;

    const el = vnode.el = document.createElement(vnode.tag)
    if(vnode.props){
      for(const key in vnode.props){
        const value = vnode.props[key]
        if(key.startsWith("on")){
          el.addEventListener(key.slice(2).toLowerCase(),value)
        } else {
          el.setAttribute(key,value)
        }
      }
    }
    if(vnode.children){
      if(typeof vnode.children === "string"){
        el.textContent = vnode.children
      } else {
        vnode.children.forEach(item => {
          this.mount(item,el)
        });
      }
    }
    container.appendChild(el)
  }
}

export default PopUp