<template>
  <div class="datePicker">
    <span class="label" v-if="label">{{ label }}</span>
    <div class="mock-picker">
      <div class="self-picker" @click="getMonthDays(date)">
        <span class="date-info">{{ joiningDate }}</span>
        <img src="@/assets/images/close.png" v-if="showClose" class="close" @click.stop="removeDate" alt="">
        <img class="date-icon" src="@/assets/images/date-icon.png" alt="">
      </div>

      <div class="date-card" v-show="showDateCard" ref="date-card" @blur="showDateCard = false" tabindex="1">
        <div class="year-month">
          <span>{{ monthEn[curDateInfo.month - 1] }} {{ curDateInfo.year }}</span>
          <div class="tab-month">
            <img @click="tabMonth('prev')" src="@/assets/images/prev_month_select.png" alt="">
            <img @click="tabMonth('next')" src="@/assets/images/next_month_select.png" alt="">
          </div>
        </div>

        <div class="weeks">
          <span class="week" v-for="(week, idx) in weeks" :key="idx">{{ week }}</span>
        </div>

        <div class="dates">
          <div :class="['day', !item.curMonth && 'not-curMonth', item.select && 'select']" 
            v-for="(item, idx) in days" :key="idx" @click="selectDay(item)">{{ item.day }}</div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import getNodeOffset from '@/utils/getNodeOffset'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    joiningDate: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      date: '',
      showDateCard: false,

      monthEn: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      weeks: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
      days: [],
      curDateInfo: {year: '', month: ''}
    }
  },
  computed: {
    showClose: function() {
      if(this.joiningDate) {
        return true
      }

      return false
    }
  },
  methods: {
    removeDate() {
      this.$emit('updateDate', '')
    },
    // 选中日期
    selectDay(day) {
      if(day.curMonth) {
        this.days.filter(its => its.timeC == day.timeC ? its.select = !day.select : its.select = false)
        this.date = day.timeC
        let joiningDate = day.timeC == '' ? day.timeC : new Date(day.timeC).format('YYYY/MM/dd')
       
        this.$emit('updateDate', joiningDate)
        let dateCard = document.querySelector('.date-card')
        dateCard.blur()
      }
    },
    // 切换月份
    tabMonth(action) {
      let {curDateInfo} = this
      switch(action) {
        case 'prev': 
          if(curDateInfo.month == 1) {
            curDateInfo['year'] -= 1;
            curDateInfo['month'] = 12;
          } else curDateInfo['month'] -= 1;
          break;
        case 'next': 
          if(curDateInfo.month == 12) {
            curDateInfo['year'] += 1;
            curDateInfo['month'] = 1;
          } else curDateInfo['month'] += 1;
          break;
      }

      this.getMonthDays(`${curDateInfo['year']}/${curDateInfo['month']}`)
    },
    /**
     * 获取月份的日历
     * year: 年份
     * month: 月份
     * monthDays: 当月总天数
     * prevMonthDays: 上月总天数
     * oneDayWeek: 1号是星期几
     */
    getMonthDays(timeObj = '') {
      let date = !timeObj ? new Date(): new Date(timeObj), 
      checkTimeC = this.date ? new Date(this.date).getTime() : new Date().getTime(),
      year = date.getFullYear(),
      month = date.getMonth() + 1,
      monthDays = new Date(year, month, 0).getDate(),
      prevMonthDays = new Date(year, month - 1, 0).getDate(),
      oneDayWeek = new Date(year, month - 1, 1).getDay()

      // 重置
      let days = []
      // 上月
      for(let i = 0; i < oneDayWeek; i++) {
        days.unshift({
          day: prevMonthDays - i,
          curMonth: false,
          select: false,
          timeC: new Date(year, month - 2, prevMonthDays - i).getTime()
        })
      }

      // 当月
      for(let i = 1; i <= monthDays; i++) {
        days.push({
          day: i,
          curMonth: true,
          select: checkTimeC == new Date(year, month - 1, i).getTime(),
          timeC: new Date(year, month - 1, i).getTime()
        })
      }

      // 下月 判断日期是否存满 28, 35, 42
      let nextDays = days.length % 7 == 0 ? 0 : (7 - days.length % 7)
      for(let i = 1; i <= nextDays; i++) {
        days.push({
          day: i,
          curMonth: false,
          select: false,
          timeC: new Date(year, month, i).getTime()
        })
      }

      this.days = days
      this.curDateInfo = {year, month}
      this.showDateCard = true

      this.$nextTick(() => {
        console.clear()
        let dateCard = document.querySelector('.date-card')
        dateCard.focus()

        // ----------  防止日历超出屏幕
        // 获取屏幕宽度
        let windowWidth = window.innerWidth
        let dateCardRight = dateCard.offsetWidth,
        dateCardOffsetLeft = getNodeOffset(dateCard).left,
        diff = dateCardRight + dateCardOffsetLeft - windowWidth,
        padding = 40,
        scroll = 0
        // 判断是否存在滚动条
        if(this.hasScrollbar()) scroll = this.getScrollbarWidth()

        if((diff + scroll) > 0) 
          dateCard.style.left = `${dateCardOffsetLeft - diff - padding - scroll}px`
      })
    },
    // 判断是否有滚动条的方法
    hasScrollbar() {
      return document.body.scrollHeight > (window.innerHeight || document.documentElement.clientHeight);
    },
    // 计算滚动条宽度的方法
    // 新建一个带有滚动条的 div 元素，通过该元素的 offsetWidth 和 clientWidth 的差值即可获得
    getScrollbarWidth() {
      var scrollDiv = document.createElement("div");
      scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
      document.body.appendChild(scrollDiv);
      var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
      return scrollbarWidth;
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.datePicker {
  display: flex;
  align-items: center;

  .label {
    white-space: nowrap;
    font-size: 1.125rem;
    margin-right: .625rem;
    margin-left: 1.25rem;
  }
  .mock-picker {
    width: 225px;
    height: 50px;

    .self-picker {
      width: 100%;
      height: 100%;
      cursor: pointer;
      border-radius: .9375rem;
      border: 1px solid #6D6D6D;
      background-color: #fff;
      padding: 0 1.25rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .date-info {
        font-size: 1.125rem;
      }

      .date-icon {
        width: 30px;
        height: auto;
      }

      .close {
        position: absolute;
        right: 60px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 20px;
        height: auto;
        cursor: pointer;
        opacity: 1;
      }
    }

    .date-card {
      position: absolute;
      // left: -50%;
      // right: -50%;
      margin: auto;
      margin-top: 10px;
      width: 500px;
      border-radius: 15px;
      background-color: #fff;
      padding: 25px;
      box-sizing: border-box;
      box-shadow: 0 10px 28px 0 rgba(0, 0, 0, .25);
      outline: none;
      z-index: 9999;

      .year-month {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        font-size: 24px;
        font-weight: 700;

        .tab-month {
          width: 100px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            width: auto;
            height: 46px;
            cursor: pointer;
            &:hover {
              box-shadow:  0 5px 10px 0 rgba(0, 0, 0, .25);
            }
          }
        }
      }

      .weeks {
        width: 100%;
        height: 64px;
        display: flex;
        .week {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          font-size: 14px;
          font-weight: 700;
        }
      }

      .dates {
        width: calc(100% - 1px);
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        border-top: 1px solid #D5D4DF;
        border-left: 1px solid #D5D4DF;
        box-sizing: border-box;
        .day {
          width: 64px;
          height: 64px;
          border-right: 1px solid #D5D4DF;
          border-bottom: 1px solid #D5D4DF;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000;
          font-size: 16px;
          cursor: pointer;
        }
        .select {
          color: #fff;
          background-color: #1B7379;
        }
        .not-curMonth {
          color: #D5D4DF;
          background-color: #F0F0EF;
        }
      }
    }
  }
}
</style>