
export const mixin = {
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMAGE_PATH
    }
  },
  methods: {
    onBack() {
      this.$router.go(-1)
    },
   
  }
}
