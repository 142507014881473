import Vue from "vue"
import VueRouter from "vue-router"
import login from './modules/security'
import eatery from './modules/eatery'
import group from './modules/eateryGroup'
import members from './modules/appMembers'
import stamp from './modules/stampTransactions'
import GTransactions from './modules/GTransactions'
import notice from './modules/notice'
import settings from './modules/settings'
import campaign from './modules/campaign'
import datePicker from  '../components/DatePicker/timePicker.vue'
import store from '@/store'
import { getToken } from '@/utils/auth'
Vue.use(VueRouter)

export const routes = [
  {
    path:'/', redirect:'/eatery', component: () => import('@/layout/index'), children : [
      ...eatery,
      ...group,
      ...members,
      ...stamp,
      ...GTransactions,
      ...notice,
      ...settings,
      ...campaign,
      {path: '/jwt', component: () => import('@/views/jwt')},
      {path: '/datePicker', component: datePicker}
    ]
  },
  ...login,
  { path: '*', component: () => import('@/views/404') }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

let whitelist = [
  '/login',
]

router.beforeEach(async(to, from, next) => {
  let userinfo = store.getters.adminUserInfo || {}
  
  if (getToken() && userinfo.position != undefined) {
    if(userinfo.position == 2) {
      whitelist = [
        '/login', '/eatery', '/eatery/edit', '/eatery/add', `/eateryGroup/edit`, '/stampTransactions'
      ]
    } else if(userinfo.position == 3) {
      whitelist = [
        '/login', `/eatery/edit`, '/stampTransactions'
      ]
    } 
    console.log(userinfo)
    if (to.path === '/login') {
      if(userinfo.position == 3) next({ path: `/eatery/edit/${userinfo.eateryId}` })
      else next({ path: '/' })
    } else if (userinfo.position != 1 && !whitelist.includes(to.meta.role) ) {
      console.log(to.meta.role)
      next({path: from.path})
    } else {
      
      next()
    }
  } else {
    if (whitelist.includes(to.meta.role) ) {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})

export default router