import moment from 'moment'
/* 时间过滤器 - 日/月/年 */
export function formatDate(value) {
  return value ? moment(value).format('YYYY/MM/DD') : ''
}

export function formatDateTime(value) {
  return value ? moment(value).format('YYYY/MM/DD HH:mm:ss') : ''
}

export function formatTime(value) {
  return value ? moment(value).format('HH:mm:ss') : ''
}

///html标签替换
export function richText(value){
  return  value !==''? value.replace(/<.+?>/g, ''): '';
}

///判断输入值是否为正整数
export function isPositiveInteger(s){
  
  if((/(^[1-9]\d*$)/.test(s))) {
     return `+${s}`
  } else {
    return s
  }
  
}   

//// 全字母大写
export function toUpperCase(value) {
   return   value.slice(0,1).toUpperCase() +value.slice(1).toLowerCase(); 
}
//// 全字母大写
export function replace(value) {
   return value !==''? value.replace(/zh/g,''): ''
}

///设置绝对值

export function abs(value){
   return Math.abs(value); 
}

export function ellipsis(value){
  if (!value) return '';
  if (value.length > 100) {
    return value.slice(0,100) + '...'
  }
  return value
}

export function toFixed(value){
  return value.toFixed(1)
}