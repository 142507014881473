import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/index.scss'
import ElementUi from 'element-ui'
import * as filters from './filters'
import echarts from 'echarts'
import store from '@/store'
import { mixin } from '@/mixin/index'
import locale from 'element-ui/lib/locale/lang/en'
import ModuleHeader from './components/Module-Header/module-header.vue'
import Pagination from "@/components/Pagination";
import datePicker from '@/components/DatePicker/datePicker.vue'
import PopUpComponent from '@/components/PopUp/popUp.vue';
import { toCamelCase } from './utils/data-format'

import PopUp from '@/components/PopUp/popUp.js'
Vue.prototype.$popUp = new PopUp()

import './utils/time-format'

Vue.prototype.$echarts = echarts

Vue.use(ElementUi, { locale })
Vue.config.productionTip = false
Vue.component('Module-Header', ModuleHeader)
Vue.component('Pagination', Pagination)
Vue.component('DatePicker', datePicker)
Vue.component('PopUp', PopUpComponent)

window.toCamelCase = toCamelCase

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.mixin({
  ...mixin
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
